import './Frontpage.scss';
import Button from 'react-bootstrap/Button';
import LogoImage from './images/app.png';

function Frontpage() {
    return (
        <>
  <div class="px-4 py-5 my-5 text-center">
    <img class="d-block mx-auto mb-4" src={LogoImage} alt="BrainTwinkle logo" width="256" height="256" />
    <h1 class="display-5 fw-bold">BrainTwinkle</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Illuminating Young Minds with Engaging Educational Games! 🌟</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <button type="button" class="btn btn-primary btn-lg px-4 gap-3">Play Now</button>
      </div>
    </div>
  </div>
   
        </>
    );
}

export default Frontpage;